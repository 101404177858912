import React from "react";
import Layout from "../../../components/layout";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
const StyledWrapper = styled.div`
`;
export default function App() {
  return (
    <Layout>
      <StyledWrapper className="pt-lg-5 pt-4">
        <div className="container">
          <div >
            <h1 className='na-head mb-4'>APPLY TO THE SOCIAL MOBILITY NETWORK</h1>
          </div>
          <div>
            <p>Thank you for submitting your application to join the Social Mobility Network. Our team will be in touch shortly via email with next steps.</p>
            <p>If you have any questions about your application, please contact the team at smn@upreach.org.uk.</p>
          </div>
          <div className="row " style={{ justifyContent: "center" }}>
            <img src="/static/w-bg-514b64890472043fb034e55a797a44bb.gif" alt="" class="w-50 mb-3 img-fluid" />
          </div>
        </div>
      </StyledWrapper>
    </Layout>
  );
}